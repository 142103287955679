
import { mdiTimerSand } from "@mdi/js"
export default {
    name: "PassauHeader",
    icons: {
        mdiTimerSand,
    },
    props: {
        blok: {
            type: Object,
            required: true,
        },
    },
    methods: {
        getBackgroundStyle(file) {
            return { "background-image": `url(${file.filename})` }
        },
    },
}
